import clsx from 'clsx';
import { Link } from './link';
export function Text({
  className,
  ...props
}: React.ComponentPropsWithoutRef<'p'>) {
  return <p data-slot="text" {...props} className={clsx(className, 'text-base/6 text-zinc-500 dark:text-zinc-400 sm:text-sm/6')} data-sentry-component="Text" data-sentry-source-file="text.tsx" />;
}
export function TextSm({
  className,
  ...props
}: React.ComponentPropsWithoutRef<'p'>) {
  return <p data-slot="text" {...props} className={clsx(className, className === 'zinc' ? 'dark:text-zinc-400' : 'dark:text-white', 'text-xs/4 font-medium text-black')} data-sentry-component="TextSm" data-sentry-source-file="text.tsx" />;
}
export function SectionFormText({
  className,
  ...props
}: React.ComponentPropsWithoutRef<'p'>) {
  return <p data-slot="text" {...props} className={clsx(className, 'text-sm/4 text-black dark:text-white')} data-sentry-component="SectionFormText" data-sentry-source-file="text.tsx" />;
}
export function SectionFormTextDark({
  className,
  ...props
}: React.ComponentPropsWithoutRef<'p'>) {
  return <p data-slot="text" {...props} className={clsx(className, 'text-sm/4 text-black')} data-sentry-component="SectionFormTextDark" data-sentry-source-file="text.tsx" />;
}
export function SectionFormText_1({
  className,
  ...props
}: React.ComponentPropsWithoutRef<'p'>) {
  return <p data-slot="text" {...props} className={clsx(className, 'text-base/6 text-black dark:text-white sm:text-sm/6')} data-sentry-component="SectionFormText_1" data-sentry-source-file="text.tsx" />;
}
export function SectionFromHeader({
  className,
  ...props
}: React.ComponentPropsWithoutRef<'p'>) {
  return <p data-slot="text" {...props} className={clsx(className, 'text-lg text-white')} data-sentry-component="SectionFromHeader" data-sentry-source-file="text.tsx" />;
}
export function SectionWithIcon({
  className,
  ...props
}: React.ComponentPropsWithoutRef<'p'>) {
  return <p data-slot="text" {...props} className={clsx(className, 'text-base/6 text-black dark:text-white sm:text-sm/6')} data-sentry-component="SectionWithIcon" data-sentry-source-file="text.tsx" />;
}
export function SectionWithIconZine({
  className,
  ...props
}: React.ComponentPropsWithoutRef<'p'>) {
  return <p data-slot="text" {...props} className={clsx(className, 'text-base/6 text-contentSecondary dark:text-contentSecondary')} data-sentry-component="SectionWithIconZine" data-sentry-source-file="text.tsx" />;
}
export function TextSecondary({
  className,
  ...props
}: React.ComponentPropsWithoutRef<'p'>) {
  return <p data-slot="text" {...props} className={clsx(className, 'text-xl/6 text-zinc-500 dark:text-zinc-400')} data-sentry-component="TextSecondary" data-sentry-source-file="text.tsx" />;
}
export function TextSecondaryWhite({
  className,
  ...props
}: React.ComponentPropsWithoutRef<'p'>) {
  return <p data-slot="text" {...props} className={clsx(className, 'text-xl/6 text-zinc-500 dark:text-white')} data-sentry-component="TextSecondaryWhite" data-sentry-source-file="text.tsx" />;
}
export function TextDescription({
  className,
  ...props
}: React.ComponentPropsWithoutRef<'p'>) {
  return <p data-slot="text" {...props} className={clsx(className, 'text-lg/[27px] text-zinc-500 dark:text-white')} data-sentry-component="TextDescription" data-sentry-source-file="text.tsx" />;
}
export function TextDescriptionzinc({
  className,
  ...props
}: React.ComponentPropsWithoutRef<'p'>) {
  return <p data-slot="text" {...props} className={clsx(className, 'text-lg/[27px] text-zinc-500 dark:text-zinc-400')} data-sentry-component="TextDescriptionzinc" data-sentry-source-file="text.tsx" />;
}
export function TextLink({
  className,
  ...props
}: React.ComponentPropsWithoutRef<typeof Link>) {
  return <Link {...props} className={clsx(className, 'text-zinc-950 underline decoration-zinc-950/50 data-[hover]:decoration-zinc-950 dark:text-white dark:decoration-white/50 dark:data-[hover]:decoration-white')} data-sentry-element="Link" data-sentry-component="TextLink" data-sentry-source-file="text.tsx" />;
}
export function Strong({
  className,
  ...props
}: React.ComponentPropsWithoutRef<'strong'>) {
  return <strong {...props} className={clsx(className, 'font-medium text-zinc-950 dark:text-white')} data-sentry-component="Strong" data-sentry-source-file="text.tsx" />;
}
export function Code({
  className,
  ...props
}: React.ComponentPropsWithoutRef<'code'>) {
  return <code {...props} className={clsx(className, 'rounded border border-zinc-950/10 bg-zinc-950/[2.5%] px-0.5 text-sm font-medium text-zinc-950 dark:border-white/20 dark:bg-white/5 dark:text-white sm:text-[0.8125rem]')} data-sentry-component="Code" data-sentry-source-file="text.tsx" />;
}